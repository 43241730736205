<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <!-- header -->
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <div class="flex">
          <ChevronLeftIcon
            @click="naviageToManageProductCategory"
            class="h-7 w-7 pt-2 cursor-pointer"
          />
          <h2 class="font-semibold">New Product Category</h2>
        </div>
      </div>
      <!-- end of header -->

      <!-- content -->
      <div v-if="isLoading" class="loader"></div>

      <div v-if="!isLoading" class="mt-5">
        <form @submit.prevent="addProductCategory" class="card">
          <h4 class="font-thin mb-2 text-black">Category Name</h4>
          <input
            v-model="productCategoryName"
            type="text"
            class="
              shadow
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              text-gray-700
              leading-tight
              focus:outline-none focus:shadow-outline
            "
            required
          />
          <br />
          <button class="btn bg-green-500 w-full border-none">Submit</button>
        </form>
      </div>
      <!-- end of content -->
    </div>
  </div>
</template>

<script>
import SideNavBar from "../../components/SideNavBar.vue";

import { ChevronLeftIcon } from "@heroicons/vue/outline";

export default {
  name: "AddProductCategory",
  components: { SideNavBar, ChevronLeftIcon },
  data() {
    return {
      isLoading: false,
      productCategoryName: "",
    };
  },
  methods: {
    addProductCategory() {
      this.isLoading = true;
      this.axios({
        url: "/productCategory/addProductCategory",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          productCategoryName: this.productCategoryName,
        },
      })
        .then((response) => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: response.data["message"],
          });
          this.isLoading = false;
          this.$router.push({ name: "ManageProductCategory" });
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    naviageToManageProductCategory() {
      this.$router.push({ name: "ManageProductCategory" });
    },
  },
};
</script>

<style scoped>
</style>